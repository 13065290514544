@media (max-width: 991px) {
  .main_section {
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .utp_wrapper {
    margin-top: 80px;
  }

  .h1_heading {
    font-size: 40px;
  }

  .main_btn.on_proj {
    display: block;
    padding-right: 20px;
    padding-left: 20px;
  }

  .page_section {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .page_section.long {
    padding-bottom: 80px;
  }

  .page_section_grey {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .project_block.no_project {
    padding-right: 30px;
    padding-left: 30px;
  }

  .text_point.li {
    margin-top: 30px;
  }

  .vid_remonta_block {
    max-width: 300px;
    min-width: 233px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-flex: 0 1 33%;
    -webkit-flex: 0 1 33%;
    -ms-flex: 0 1 33%;
    flex: 0 1 33%;
  }

  .vid_remonta_block.popylyarnaya_usluga {
    margin-right: 15px;
    margin-left: 15px;
  }

  .form_section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .footer_block {
    -ms-grid-columns: 1.25fr 16px 1.25fr 16px 0.5fr 16px 0.6fr;
    grid-template-columns: 1.25fr 1.25fr 0.5fr 0.6fr;
  }
}

@media (max-width: 767px) {
  .main_section {
    padding-bottom: 80px;
  }

  .logo_wrapper {
    margin-left: 0px;
  }

  .utp_wrapper {
    max-width: 400px;
    margin-top: 60px;
  }

  .h1_heading {
    font-size: 32px;
  }

  .hi_subtitle {
    font-size: 22px;
  }

  .page_section {
    padding-top: 60px;
    padding-bottom: 35px;
  }

  .page_section.long {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .h2_heading {
    font-size: 28px;
  }

  .h2_subtitle {
    font-size: 18px;
  }

  .bulets_wrapper {
    display: block;
    margin-top: 40px;
  }

  .bulets_wrapper.block_preimushectv {
    margin-top: 40px;
  }

  .bulet_block {
    margin-bottom: 25px;
  }

  .bulet_text_block {
    margin-left: 25px;
  }

  .bulets_heading {
    font-size: 16px;
    line-height: 120%;
  }

  .page_section_grey {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .project_wrapper {
    margin-top: 40px;
    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto 30px auto 30px auto;
    grid-template-rows: auto auto auto;
  }

  .project_block:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .project_block:nth-child(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  .project_block:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
  .project_block:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }
  .project_block:nth-child(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }
  .project_block:nth-child(6) {
    -ms-grid-column: 3;
    -ms-grid-row: 5;
  }

  .project_block.no_project {
    padding-right: 22px;
    padding-left: 22px;
  }

  .project_text_block {
    padding-right: 22px;
    padding-bottom: 22px;
    padding-left: 22px;
  }

  .vid_remonta_block {
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
  }

  .vid_remonta_block.popylyarnaya_usluga {
    margin-right: auto;
    margin-left: auto;
  }

  .form_section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .form_wrapper {
    margin-right: auto;
    margin-left: auto;
  }

  .footer_block {
    -ms-grid-columns: 1.75fr 16px 0.25fr 16px 1.25fr 16px 0.6fr;
    grid-template-columns: 1.75fr 0.25fr 1.25fr 0.6fr;
    -ms-grid-rows: auto 16px auto 16px auto;
    grid-template-rows: auto auto auto;
  }


  .logo_block {
    margin-left: 100px;
  }

  .menu_btn {
    display: block;
  }

  .navigation_block {
    position: absolute;
    left: 0;
    margin-left: 0px;
    top: -12px;
  }

  .nav_menu {
    background-color: #faf9f7;
    overflow-y: hidden;
    height: 0;
    transition-property: height;
    transition-timing-function: ease;
    transition-duration: 0.3s;
  }

  .nav_menu_open {
    height: 134px;
  }
  
  .nav_menu ul {
    display: block;
    margin: 10px 10px 1px 10px;
  }
  .nav_menu ul li {
    margin: 0 0 10px 0;
  }

  .nav_menu ul li a::before {
    width: 100%;
    transform: scaleX(0);
  }

  .nav_menu ul li a:hover:before {
    transform: scaleX(1);
  }
}

@media (max-width: 479px) {
  
  .navigation_block {
    top: -14px;
  }

  .logo_block {
    margin-left: 70px;
  }

  .main_phone {
    font-size: 16px;
  }

  .logo_icon {
    width: 20px;
    height: 22px;
  }

  .logo_text {
    font-size: 16px;
  }

  .h1_heading {
    font-size: 30px;
  }

  .hi_subtitle {
    font-size: 20px;
  }

  .h2_heading {
    font-size: 22px;
  }

  .h2_subtitle {
    font-size: 18px;
  }

  .project_wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 30px auto 30px auto 30px auto 30px auto 30px auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .project_block {
    width: 280px;
    margin-right: auto;
    margin-left: auto;
  }

  .project_block:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .project_block:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
  .project_block:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }
  .project_block:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 7;
  }
  .project_block:nth-child(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 9;
  }
  .project_block:nth-child(6) {
    -ms-grid-column: 1;
    -ms-grid-row: 11;
  }

  .project_block.no_project {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .text-block-3 {
    font-size: 20px;
  }

  .footer_block {
    -ms-grid-columns: 1.75fr 16px 1fr;
    grid-template-columns: 1.75fr 1fr;
    -ms-grid-rows: auto 16px auto 16px auto 16px auto 16px auto;
    grid-template-rows: auto auto auto auto auto;
  }
}

@media (max-width: 400px) {
  .logo_block {
    margin-left: 50px;
  }

  .logo_menu_wrappper {
    left: -15px;
  }

  .main_phone,
  .logo_text {
    font-size: 14px;
  }

  .logo_icon {
    margin-right: 5px;
  }
}

@media screen and (max-width: 991px) {

  #w-node-b969551e2cc6-47505eeb {
    -ms-grid-row: 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: span 2;
    -ms-grid-column-span: 3;
    grid-column-end: span 2;
  }

  #w-node-f896b69ee61e-47505eeb {
    -ms-grid-row: 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 5;
    grid-column-start: span 2;
    -ms-grid-column-span: 7;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-b4bd0b592646-47505eeb {
    -ms-grid-row: 3;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: span 2;
    -ms-grid-column-span: 3;
    grid-column-end: span 2;
  }

  #w-node-1bd4b4a7b2bc-47505eeb {
    -ms-grid-row: 3;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 5;
    grid-column-start: span 2;
    -ms-grid-column-span: 7;
    grid-column-end: span 2;
  }

  #w-node-d25255134291-47505eeb {
    -ms-grid-row: 5;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: span 2;
    -ms-grid-column-span: 3;
    grid-column-end: span 2;
  }

  #w-node-14932ee1cbe9-47505eeb {
    -ms-grid-row: 5;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 5;
    grid-column-start: span 2;
    -ms-grid-column-span: 7;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-b969551e2cc6-47505eeb {
    -ms-grid-row: 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: span 1;
    grid-column-end: span 1;
  }

  #w-node-f896b69ee61e-47505eeb {
    -ms-grid-row: 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: 3;
    grid-column-start: span 1;
    grid-column-end: span 1;
  }

  #w-node-b4bd0b592646-47505eeb {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-1bd4b4a7b2bc-47505eeb {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-d25255134291-47505eeb{
    -ms-grid-row: 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-14932ee1cbe9-47505eeb {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
}
