@font-face {
  font-family: 'Proximanova';
  font-display: fallback;
  src: local('Proximanova'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype'),
  url('../fonts/ProximaNova-Regular.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proximanova';
  font-display: fallback;
  src: local('Proximanova'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype'),
  url('../fonts/ProximaNova-Bold.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}