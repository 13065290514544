.body {
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  line-height: 130%;
}

.main_section {
  padding-top: 32px;
  padding-bottom: 200px;
  background-image: url("../images/5df20a41245d00d5d2b6169c_main-img.jpg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.conteiner {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.hero_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.main_phone {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.logo_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo_icon {
  width: 23px;
  height: 25px;
  margin-right: 10px;
  background-image: url("../images/5df20a413daff8599d475029_logo.svg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.logo_text {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 5%;
  text-transform: uppercase;
}

.utp_wrapper {
  max-width: 470px;
  margin-top: 180px;
}

.h1_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 48px;
  line-height: 110%;
  font-weight: 700;
}

.hi_subtitle {
  margin-top: 20px;
  font-size: 24px;
}

.main_btn {
  display: inline-block;
  margin-top: 30px;
  padding: 14px 40px;
  border-radius: 2px;
  background-color: #f1b90a;
  color: #000;
  text-align: center;
  text-decoration: none;
}

.main_btn.for_vid_remonta {
  text-align: center;
}

.main_btn.for_vid_remonta.no_fill {
  border: 2px solid #f1b90a;
  background-color: transparent;
}

.page_section {
  padding-top: 120px;
  padding-bottom: 80px;
}

.page_section.long {
  padding-bottom: 120px;
}

.h2_heading {
  max-width: 570px;
  margin-top: 0px;
  font-size: 30px;
  font-weight: 700;
}

.h2_subtitle {
  max-width: 570px;
  font-size: 20px;
  line-height: 130%;
}

.bulets_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bulets_wrapper.block_preimushectv {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bulet_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bulet_icon {
  width: 65px;
  height: 68px;
  max-height: 68px;
  max-width: 65px;
  min-height: 68px;
  min-width: 65px;
  margin-top: 5px;
  background-image: url("../images/5df20a419bca42aca31d0d14_bulet-icon.svg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.bulet_text_block {
  margin-left: 35px;
}

.bulets_heading {
  max-width: 270px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 700;
}

.text-block {
  max-width: 355px;
}

.page_section_grey {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #faf9f7;
}

.project_wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto 30px auto;
  grid-template-rows: auto auto;
}

.project_block {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(74, 56, 7, 0.1);
}

.project_block.no_project {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 1px 1px 30px 0 rgba(74, 56, 7, 0.1);
}

.project_block:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.project_block:nth-child(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}
.project_block:nth-child(3) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
}
.project_block:nth-child(4) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.project_block:nth-child(5) {
  -ms-grid-column: 3;
  -ms-grid-row: 3;
}
.project_block:nth-child(6) {
  -ms-grid-column: 5;
  -ms-grid-row: 3;
}

.project_img {
  overflow: hidden;
  height: 210px;
  margin-bottom: 30px;
}
.project_img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.project_text_block {
  padding-right: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
}

.div-block {
  max-width: 246px;
}

.text_point.infoter {
  margin-bottom: 10px;
}

.text_point.li {
  margin-top: 30px;
  font-size: 12px;
}

.vid_remonta_block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 45px;
  box-sizing: border-box;
  max-width: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 30%;
  border: 0.5px solid #f1b90a;
  border-radius: 2px;
  box-shadow: 1px 1px 30px 0 rgba(74, 56, 7, 0.1);
}

.price_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.price_text_sum {
  font-size: 30px;
  line-height: 130%;
  font-weight: 700;
}

.edinica_izmereniay {
  position: relative;
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.ruble {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  font-size: 14px;
  font-weight: 700;
}

.kv_metri {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  font-size: 14px;
  font-weight: 700;
}

.drob {
  margin-top: 16px;
  margin-left: -6px;
  border-top: 1px solid #000;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rastayg {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.akciay {
  position: absolute;
  left: auto;
  top: 9px;
  right: -10px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  height: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f1b90a;
}
.akciay:after {
  content: '';
  position: absolute;
  top: 0;
  left: -17px;
  border: 17.5px solid transparent;
  border-top: 17.5px solid #f1b90a;
  border-right: 17.5px solid #f1b90a;
  border-bottom: 17.5px solid #f1b90a;

}

.ugolok {
  background-color: #9a740f;
  position: relative;
}
.ugolok:after {
  content: '';
  position: absolute;
  right: -20px;
  bottom: -27px;
  border: 5px solid transparent;
  border-left: 5px solid #9a740f;
  border-top: 5px solid #9a740f;
}

.form_section {
  padding-top: 115px;
  padding-bottom: 115px;
  background-image: url("../images/5df20a413daff8cefc47502a_foter_img.jpg");
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.form_wrapper {
  max-width: 370px;
  padding: 47px 53px;
  background-color: #fff;
}

.text-block-3 {
  margin-bottom: 13px;
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
}

.form {
  margin-top: 18px;
}

.field-label {
  font-weight: 400;
}

.text-field {
  border: 1px solid #f1b90a;
  border-radius: 2px;
}

.submit-button {
  width: 100%;
  border-radius: 2px;
  background-color: #f1b90a;
  color: #000;
  font-size: 14px;
  text-align: center;
}

.checkbox-label {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 100%;
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
}

.checkbox-field {
  display: block;
  margin-top: 15px;
  margin-bottom: 0px;
  padding-left: 36px;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-left: -36px;
  border-color: #f1b90a;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  float: left;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.checkbox__checked {
  background-color: #f1b90a;
  border-color: #f1b90a;
  background-image: url(../images/custom-checkbox-checkmark.589d534424.svg);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.form-block {
  margin-bottom: 0px;
}

.footer_section {
  padding-top: 33px;
  padding-bottom: 33px;
}

.footer_block {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.25fr 16px 1fr 16px 0.5fr 16px 0.75fr;
  grid-template-columns: 1.25fr 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto 16px auto;
  grid-template-rows: auto auto;
}

.link {
  margin-bottom: 10px;
  color: #000;
  text-decoration: underline;
}

.soc_icon_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 130px;
  margin-top: 28px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.soc_link {
  width: auto;
  min-height: 15px;
}

.logo_menu_wrappper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  text-decoration: none;
}

.navigation_block {
  margin-left: 45px;
}

.menu_btn {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;
}
.menu_btn span,
.menu_btn span::before,
.menu_btn span::after {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
  background-color: #333;
}
.menu_btn span::before,
.menu_btn span::after {
  content: '';
  display: block;
  transition: 0.2s;
}
.menu_btn span::before {
  transform: translateY(-5px);
}
.menu_btn span::after {
  transform: translateY(5px);
}
.menu_btn_active span{
  height: 0;
}
.menu_btn_active span::before{
  transform: rotate(45deg);
}
.menu_btn_active span::after {
  transform: rotate(-45deg);
}


.nav_menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav_menu ul li {
  margin-right: 20px;
}
.nav_menu ul li a{
  text-decoration: none;
  color: #333;
  font-size: 14px;
  position: relative;
  padding: 0 3px;
}
.nav_menu ul li a::before {
  content: '';
  bottom: 0;
  right: 0;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #f1b90a;
  transition: 0.3s;
}
.nav_menu ul li a:hover:before {
  width: 100%;
  left: 0;
}


#w-node-b969551e2cc6-47505eeb {
  -ms-grid-row: 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
  -ms-grid-column: 1;
  grid-column-start: span 3;
  -ms-grid-column-span: 5;
  grid-column-end: span 3;
}

#w-node-f896b69ee61e-47505eeb {
  -ms-grid-row: 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
  -ms-grid-column: 7;
  grid-column-start: span 1;
  grid-column-end: span 1;
}

#w-node-b4bd0b592646-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

#w-node-1bd4b4a7b2bc-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}

#w-node-d25255134291-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
}

#w-node-14932ee1cbe9-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 7;
}