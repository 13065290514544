* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

html {
  height: 100%
}

body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block
}

.w-inline-block {
  max-width: 100%;
  display: inline-block
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0
}

input.w-button {
  -webkit-appearance: button
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px
}

button,
html input[type="button"],
input[type="reset"] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button
}

.w-form {
  margin: 0 0 15px
}

.w-form-done {
  display: none;
  margin-top: 10px;
  padding: 20px;
  text-align: center;
  background-color: #97f78f
}

.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999
}

.w-input:focus,
.w-select:focus {
  border-color: #3898EC;
  outline: 0
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
  background-color: #eeeeee
}