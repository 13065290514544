html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: Proximanova;
  font-display: fallback;
  src: local(Proximanova), url("ProximaNova-Regular.9ca2cacf.woff") format("woff"), url("ProximaNova-Regular.21320d14.ttf") format("truetype"), url("ProximaNova-Regular.131519b6.eot") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Proximanova;
  font-display: fallback;
  src: local(Proximanova), url("ProximaNova-Bold.7e04a80d.woff") format("woff"), url("ProximaNova-Bold.0fd5f275.ttf") format("truetype"), url("ProximaNova-Bold.6dba70cd.eot") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  color: #333;
  background-color: #fff;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

input.w-button {
  -webkit-appearance: button;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 10px;
  font-weight: bold;
}

h1 {
  margin-top: 20px;
  font-size: 38px;
  line-height: 44px;
}

h2 {
  margin-top: 20px;
  font-size: 32px;
  line-height: 36px;
}

h3 {
  margin-top: 20px;
  font-size: 24px;
  line-height: 30px;
}

button, html input[type="button"], input[type="reset"] {
  cursor: pointer;
  -webkit-appearance: button;
  border: 0;
}

.w-form {
  margin: 0 0 15px;
}

.w-form-done {
  text-align: center;
  background-color: #97f78f;
  margin-top: 10px;
  padding: 20px;
  display: none;
}

.w-form-fail {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
  display: none;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.w-input, .w-select {
  width: 100%;
  height: 38px;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.w-input:-moz-placeholder, .w-select:-moz-placeholder {
  color: #999;
}

.w-input::-moz-placeholder, .w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.w-input:-ms-input-placeholder, .w-select:-ms-input-placeholder {
  color: #999;
}

.w-input::-webkit-input-placeholder, .w-select::-webkit-input-placeholder {
  color: #999;
}

.w-input:focus, .w-select:focus {
  border-color: #3898ec;
  outline: 0;
}

.w-input[disabled], .w-select[disabled], .w-input[readonly], .w-select[readonly], fieldset[disabled] .w-input, fieldset[disabled] .w-select {
  cursor: not-allowed;
  background-color: #eee;
}

.body {
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  line-height: 130%;
}

.main_section {
  background-image: url("5df20a41245d00d5d2b6169c_main-img.f27ad9f3.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 32px;
  padding-bottom: 200px;
}

.conteiner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.hero_wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main_phone {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.logo_wrapper {
  align-items: center;
  margin-left: 0;
  display: flex;
}

.logo_icon {
  width: 23px;
  height: 25px;
  background-image: url("5df20a413daff8599d475029_logo.59e94f42.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.logo_text {
  letter-spacing: 5%;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}

.utp_wrapper {
  max-width: 470px;
  margin-top: 180px;
}

.h1_heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 110%;
}

.hi_subtitle {
  margin-top: 20px;
  font-size: 24px;
}

.main_btn {
  color: #000;
  text-align: center;
  background-color: #f1b90a;
  border-radius: 2px;
  margin-top: 30px;
  padding: 14px 40px;
  text-decoration: none;
  display: inline-block;
}

.main_btn.for_vid_remonta {
  text-align: center;
}

.main_btn.for_vid_remonta.no_fill {
  background-color: #0000;
  border: 2px solid #f1b90a;
}

.page_section {
  padding-top: 120px;
  padding-bottom: 80px;
}

.page_section.long {
  padding-bottom: 120px;
}

.h2_heading {
  max-width: 570px;
  margin-top: 0;
  font-size: 30px;
  font-weight: 700;
}

.h2_subtitle {
  max-width: 570px;
  font-size: 20px;
  line-height: 130%;
}

.bulets_wrapper {
  justify-content: space-between;
  margin-top: 60px;
  display: flex;
}

.bulets_wrapper.block_preimushectv {
  flex-wrap: wrap;
}

.bulet_block {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.bulet_icon {
  width: 65px;
  height: 68px;
  max-height: 68px;
  max-width: 65px;
  min-height: 68px;
  min-width: 65px;
  background-image: url("5df20a419bca42aca31d0d14_bulet-icon.04e7b5ba.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5px;
}

.bulet_text_block {
  margin-left: 35px;
}

.bulets_heading {
  max-width: 270px;
  margin-top: 0;
  font-size: 18px;
  font-weight: 700;
}

.text-block {
  max-width: 355px;
}

.page_section_grey {
  background-color: #faf9f7;
  padding-top: 120px;
  padding-bottom: 120px;
}

.project_wrapper {
  display: -ms-grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  -ms-grid-rows: auto 30px auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 60px;
  display: grid;
}

.project_block {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 30px #4a38071a;
}

.project_block.no_project {
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 1px 1px 30px #4a38071a;
}

.project_block:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.project_block:nth-child(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}

.project_block:nth-child(3) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
}

.project_block:nth-child(4) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}

.project_block:nth-child(5) {
  -ms-grid-column: 3;
  -ms-grid-row: 3;
}

.project_block:nth-child(6) {
  -ms-grid-column: 5;
  -ms-grid-row: 3;
}

.project_img {
  height: 210px;
  margin-bottom: 30px;
  overflow: hidden;
}

.project_img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.project_text_block {
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.div-block {
  max-width: 246px;
}

.text_point.infoter {
  margin-bottom: 10px;
}

.text_point.li {
  margin-top: 30px;
  font-size: 12px;
}

.vid_remonta_block {
  box-sizing: border-box;
  max-width: 300px;
  border: .5px solid #f1b90a;
  border-radius: 2px;
  flex-direction: column;
  flex: 0 30%;
  justify-content: flex-start;
  align-items: stretch;
  padding: 50px 45px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 30px #4a38071a;
}

.price_block {
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
}

.price_text_sum {
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
}

.edinica_izmereniay {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  position: relative;
}

.ruble {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  inset: 0% auto auto 0%;
}

.kv_metri {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  inset: auto 0% 0% auto;
}

.drob {
  border-top: 1px solid #000;
  margin-top: 16px;
  margin-left: -6px;
  transform: rotate(-45deg);
}

.rastayg {
  flex: 1;
}

.akciay {
  width: 130px;
  height: 35px;
  background-color: #f1b90a;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 9px -10px auto auto;
}

.akciay:after {
  content: "";
  border: 17.5px solid #f1b90a;
  border-left-color: #0000;
  position: absolute;
  top: 0;
  left: -17px;
}

.ugolok {
  background-color: #9a740f;
  position: relative;
}

.ugolok:after {
  content: "";
  border: 5px solid #0000;
  border-color: #9a740f #0000 #0000 #9a740f;
  position: absolute;
  bottom: -27px;
  right: -20px;
}

.form_section {
  background-image: url("5df20a413daff8cefc47502a_foter_img.cf5819c0.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 115px;
  padding-bottom: 115px;
}

.form_wrapper {
  max-width: 370px;
  background-color: #fff;
  padding: 47px 53px;
}

.text-block-3 {
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
}

.form {
  margin-top: 18px;
}

.field-label {
  font-weight: 400;
}

.text-field {
  border: 1px solid #f1b90a;
  border-radius: 2px;
}

.submit-button {
  width: 100%;
  color: #000;
  text-align: center;
  background-color: #f1b90a;
  border-radius: 2px;
  font-size: 14px;
}

.checkbox-label {
  cursor: pointer;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 100%;
  display: inline-block;
}

.checkbox-field {
  margin-top: 15px;
  margin-bottom: 0;
  padding-left: 36px;
  display: block;
}

.checkbox {
  width: 20px;
  height: 20px;
  float: left;
  border: 1px solid #f1b90a;
  border-radius: 2px;
  margin: 4px 0 0 -36px;
  line-height: normal;
}

.checkbox__checked {
  background-color: #f1b90a;
  background-image: url("custom-checkbox-checkmark.589d534424.9882caad.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #f1b90a;
}

.form-block {
  margin-bottom: 0;
}

.footer_section {
  padding-top: 33px;
  padding-bottom: 33px;
}

.footer_block {
  display: -ms-grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.25fr 16px 1fr 16px .5fr 16px .75fr;
  -ms-grid-rows: auto 16px auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1.25fr 1fr .5fr .75fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.link {
  color: #000;
  margin-bottom: 10px;
  text-decoration: underline;
}

.soc_icon_wrapper {
  max-width: 130px;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  display: flex;
}

.soc_link {
  width: auto;
  min-height: 15px;
}

.logo_menu_wrappper {
  align-items: center;
  display: flex;
  position: relative;
}

.logo_block {
  color: #333;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.navigation_block {
  margin-left: 45px;
}

.menu_btn {
  width: 50px;
  height: 50px;
  display: none;
  position: relative;
}

.menu_btn span, .menu_btn span:before, .menu_btn span:after {
  width: 20px;
  height: 2px;
  background-color: #333;
  margin-top: -1px;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.menu_btn span:before, .menu_btn span:after {
  content: "";
  transition: all .2s;
  display: block;
}

.menu_btn span:before {
  transform: translateY(-5px);
}

.menu_btn span:after {
  transform: translateY(5px);
}

.menu_btn_active span {
  height: 0;
}

.menu_btn_active span:before {
  transform: rotate(45deg);
}

.menu_btn_active span:after {
  transform: rotate(-45deg);
}

.nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav_menu ul li {
  margin-right: 20px;
}

.nav_menu ul li a {
  color: #333;
  padding: 0 3px;
  font-size: 14px;
  text-decoration: none;
  position: relative;
}

.nav_menu ul li a:before {
  content: "";
  width: 0%;
  height: 2px;
  background-color: #f1b90a;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.nav_menu ul li a:hover:before {
  width: 100%;
  left: 0;
}

#w-node-b969551e2cc6-47505eeb {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-f896b69ee61e-47505eeb {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b4bd0b592646-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

#w-node-1bd4b4a7b2bc-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}

#w-node-d25255134291-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
}

#w-node-14932ee1cbe9-47505eeb {
  -ms-grid-row: 3;
  -ms-grid-column: 7;
}

@media (max-width: 991px) {
  .main_section {
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .utp_wrapper {
    margin-top: 80px;
  }

  .h1_heading {
    font-size: 40px;
  }

  .main_btn.on_proj {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
  }

  .page_section {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .page_section.long {
    padding-bottom: 80px;
  }

  .page_section_grey {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .project_block.no_project {
    padding-left: 30px;
    padding-right: 30px;
  }

  .text_point.li {
    margin-top: 30px;
  }

  .vid_remonta_block {
    max-width: 300px;
    min-width: 233px;
    -webkit-box-flex: 0 1 33%;
    flex: 0 33%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .vid_remonta_block.popylyarnaya_usluga {
    margin-left: 15px;
    margin-right: 15px;
  }

  .form_section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .footer_block {
    -ms-grid-columns: 1.25fr 16px 1.25fr 16px .5fr 16px .6fr;
    grid-template-columns: 1.25fr 1.25fr .5fr .6fr;
  }
}

@media (max-width: 767px) {
  .main_section {
    padding-bottom: 80px;
  }

  .logo_wrapper {
    margin-left: 0;
  }

  .utp_wrapper {
    max-width: 400px;
    margin-top: 60px;
  }

  .h1_heading {
    font-size: 32px;
  }

  .hi_subtitle {
    font-size: 22px;
  }

  .page_section {
    padding-top: 60px;
    padding-bottom: 35px;
  }

  .page_section.long {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .h2_heading {
    font-size: 28px;
  }

  .h2_subtitle {
    font-size: 18px;
  }

  .bulets_wrapper {
    margin-top: 40px;
    display: block;
  }

  .bulets_wrapper.block_preimushectv {
    margin-top: 40px;
  }

  .bulet_block {
    margin-bottom: 25px;
  }

  .bulet_text_block {
    margin-left: 25px;
  }

  .bulets_heading {
    font-size: 16px;
    line-height: 120%;
  }

  .page_section_grey {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .project_wrapper {
    -ms-grid-columns: 1fr 30px 1fr;
    -ms-grid-rows: auto 30px auto 30px auto;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
  }

  .project_block:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .project_block:nth-child(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }

  .project_block:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }

  .project_block:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }

  .project_block:nth-child(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }

  .project_block:nth-child(6) {
    -ms-grid-column: 3;
    -ms-grid-row: 5;
  }

  .project_block.no_project {
    padding-left: 22px;
    padding-right: 22px;
  }

  .project_text_block {
    padding-bottom: 22px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .vid_remonta_block {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .vid_remonta_block.popylyarnaya_usluga {
    margin-left: auto;
    margin-right: auto;
  }

  .form_section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .form_wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .footer_block {
    -ms-grid-columns: 1.75fr 16px .25fr 16px 1.25fr 16px .6fr;
    -ms-grid-rows: auto 16px auto 16px auto;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1.75fr .25fr 1.25fr .6fr;
  }

  .logo_block {
    margin-left: 100px;
  }

  .menu_btn {
    display: block;
  }

  .navigation_block {
    margin-left: 0;
    position: absolute;
    top: -12px;
    left: 0;
  }

  .nav_menu {
    height: 0;
    background-color: #faf9f7;
    transition-property: height;
    transition-duration: .3s;
    transition-timing-function: ease;
    overflow-y: hidden;
  }

  .nav_menu_open {
    height: 134px;
  }

  .nav_menu ul {
    margin: 10px 10px 1px;
    display: block;
  }

  .nav_menu ul li {
    margin: 0 0 10px;
  }

  .nav_menu ul li a:before {
    width: 100%;
    transform: scaleX(0);
  }

  .nav_menu ul li a:hover:before {
    transform: scaleX(1);
  }
}

@media (max-width: 479px) {
  .navigation_block {
    top: -14px;
  }

  .logo_block {
    margin-left: 70px;
  }

  .main_phone {
    font-size: 16px;
  }

  .logo_icon {
    width: 20px;
    height: 22px;
  }

  .logo_text {
    font-size: 16px;
  }

  .h1_heading {
    font-size: 30px;
  }

  .hi_subtitle {
    font-size: 20px;
  }

  .h2_heading {
    font-size: 22px;
  }

  .h2_subtitle {
    font-size: 18px;
  }

  .project_wrapper {
    -ms-grid-columns: 1fr;
    -ms-grid-rows: auto 30px auto 30px auto 30px auto 30px auto 30px auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 1fr;
  }

  .project_block {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .project_block:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .project_block:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }

  .project_block:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }

  .project_block:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 7;
  }

  .project_block:nth-child(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 9;
  }

  .project_block:nth-child(6) {
    -ms-grid-column: 1;
    -ms-grid-row: 11;
  }

  .project_block.no_project {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .text-block-3 {
    font-size: 20px;
  }

  .footer_block {
    -ms-grid-columns: 1.75fr 16px 1fr;
    -ms-grid-rows: auto 16px auto 16px auto 16px auto 16px auto;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1.75fr 1fr;
  }
}

@media (max-width: 400px) {
  .logo_block {
    margin-left: 50px;
  }

  .logo_menu_wrappper {
    left: -15px;
  }

  .main_phone, .logo_text {
    font-size: 14px;
  }

  .logo_icon {
    margin-right: 5px;
  }
}

@media screen and (max-width: 991px) {
  #w-node-b969551e2cc6-47505eeb {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-f896b69ee61e-47505eeb {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
    -ms-grid-column-span: 7;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-b4bd0b592646-47505eeb {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-1bd4b4a7b2bc-47505eeb {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
    -ms-grid-column-span: 7;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-d25255134291-47505eeb {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-14932ee1cbe9-47505eeb {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
    -ms-grid-column-span: 7;
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-b969551e2cc6-47505eeb {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-f896b69ee61e-47505eeb {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-b4bd0b592646-47505eeb {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-1bd4b4a7b2bc-47505eeb {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-d25255134291-47505eeb {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  #w-node-14932ee1cbe9-47505eeb {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
}



/*# sourceMappingURL=index.d9800a05.css.map */
